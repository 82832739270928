<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
      </card>
    <card>
      <div class="card-title">
      <h3 class="custom-card">Plugins
        <el-tooltip content="Add New Plugin">
          <el-button class="plugin-type-btn ml-2 mt-2" @click="openModal('create')" round>Add New Plugin</el-button>
        </el-tooltip>
        <el-tooltip content="Add New Plugin Type">
          <el-button class="plugin-type-btn ml-2 mt-2" @click="openModal('plugin_type')" round>Add New Plugin Type</el-button>
        </el-tooltip>
      </h3>
      </div>
      <label class="mb-2">The purpose of this page is to create connectors so the server can monitor your systems</label>
      <div class="row pb-5">
        <div class="col-sm-6">
          <el-input
            v-model="searchQuery"
            placeholder="Search by first name, last name, username, or email"
            clearable
            @clear="searchQuery = ''">
            <span slot="prepend">Search</span>
          </el-input>
        </div>
        <div class="col-sm-2">
          <el-button @click="searchQuery = ''">Clear</el-button>
        </div>
      </div>
      <el-table :data="paginatedPlugins" border style="width: 100%">
        <el-table-column label="Name" width="250">
          <template slot-scope="scope">
            <fg-input placeholder="Name" v-model="scope.row.name"></fg-input>
          </template>
        </el-table-column>
        <el-table-column label="Description" width="300">
          <template slot-scope="scope">
            <fg-input placeholder="Description" v-model="scope.row.description"></fg-input>
          </template>
        </el-table-column>
        <el-table-column label="Plugin" width="200">
          <template slot-scope="scope">
            <div v-if="!scope.row.download_location">No Plugin</div>
            <form @submit.prevent>
              <input type="file" :ref="`fileInput${scope.$index}`" @change="fileSelected(scope.$index)" />
              <el-button @click="setPlugin(scope.row, scope.$index)">Upload</el-button>
            </form>
          </template>
        </el-table-column>
        <el-table-column label="Arch" width="100" align="center">
          <template slot-scope="scope">
            <fg-input placeholder="Arch" v-model="scope.row.arch"></fg-input>
          </template>
        </el-table-column>
        <el-table-column label="Host Type" width="100" align="center">
          <template slot-scope="scope">
            <el-select v-model="scope.row.host_type_id" filterable placeholder="Host Type">
              <el-option v-for="host_type in host_types" :key="host_type.id" :label="host_type.name"
                         :value="host_type.id">{{
                  host_type.name
                }}
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="Plugin Type" width="120" align="center">
          <template slot-scope="scope">
            <el-select v-model="scope.row.plugin_type_id" filterable placeholder="Plugins">
              <el-option v-for="plugin_type in plugin_types" :key="plugin_type.id" :label="plugin_type.name"
                         :value="plugin_type.id">{{
                  plugin_type.name
                }}
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="100" align="center">
          <template slot-scope="scope">
            <div class="center-btn">
            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css">
            <el-tooltip content="Update Plugin" placement="top">
              <el-button type="text" @click="savePlugin(scope.row)">
                <i class="fa-regular fa-floppy-disk"></i>
              </el-button>
            </el-tooltip>
            <el-tooltip content="Delete Plugin" placement="top">
            <el-button  type="text" @click="deletePlugin(scope.row.id)">
              <el-icon name="delete" class="icon-large icon-red"></el-icon>
            </el-button>
            </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="filteredPlugins.length"
      >
      </el-pagination>


    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer">
<l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>&nbsp;
</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer">
<l-button round type="btn btn-success" @click="closeModal('success')">OK</l-button>&nbsp;
</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      :visible.sync="modals.create">
      <div class="text-center">
        <h2 style="margin-top: -5px; margin-bottom: 30px;">Upload New Plugin</h2>
      </div>
      <form @submit.prevent="uploadPlugin">
        <div class="text-center">
          <div class="row">
            <div class="col-sm-6">
              <label>Name: <fg-input placeholder="Name" v-model="newPlugin.name" /></label>
            </div>
            <div class="col-sm-6">
              <label>Description: <fg-input placeholder="Description" v-model="newPlugin.description" /></label>
            </div>
          </div>
        </div>
        <div class="text-center">
          <div class="row">
            <div class="col-sm-6">
              <div>
                <label>Architecture:</label>
              </div>
              <el-select style="width: 250px;" v-model="newPlugin.arch" placeholder="Arch" id="arch">
                <el-option value="64-bit">64-bit</el-option>
                <el-option value="32-bit">32-bit</el-option>
                <el-option value="Both">Both</el-option>
              </el-select>
            </div>
            <div class="col-sm-6">
              <div>
                <label>Host Type: </label>
              </div>
                <el-select v-model="newPlugin.host_type_id" filterable placeholder="Host Type">
                  <el-option v-for="host_type in host_types" :key="host_type.id" :label="host_type.name"
                             :value="host_type.id">{{
                      host_type.name
                    }}
                  </el-option>
                </el-select>
            </div>
          </div>
        </div>

        <div class="text-center">
          <div class="row">
            <div class="col-sm-6 mt-4">
              <div>
                <label>Plugin Type: </label>
              </div>
                <el-select v-model="newPlugin.plugin_type_id" filterable placeholder="Plugin Type">
                  <el-option v-for="plugin_type in plugin_types" :key="plugin_type.id" :label="plugin_type.name"
                             :value="plugin_type.id">{{
                      plugin_type.name
                    }}
                  </el-option>
                </el-select>
            </div>
            <div class="col-sm-6">
              <input class="choose-file" type="file" ref="fileInput" />
            </div>
          </div>
        </div>
      </form>
        <span slot="footer" class="dialog-footer">
<l-button type="primary" round @click="createPlugin('create')">Save and Upload Plugin</l-button>&nbsp;
<l-button type="danger" round @click="closeModal('create')">Cancel</l-button>&nbsp;
</span>
    </el-dialog>
    <el-dialog
      center
      :visible.sync="modals.plugin_type">
      <div class="text-center">
        <h4 style="margin-top: -10px; margin-bottom: 30px;">Upload PDF</h4>
      </div>
      <div class="text-center">
        <div class="row">
          <div class="col-sm-12">
            <label style="font-size: 17px;">Name:
              <fg-input class="plugin-type" v-model="plugin_type_name" placeholder="Enter a new plugin type name"></fg-input>
            </label>
          </div>
        </div>
        <br>
        <span slot="footer" class="dialog-footer">
<l-button round type="primary" @click="createNewPluginType">Create Plugin Type</l-button>&nbsp;
<l-button round type="danger" @click="closeModal('plugin_type')">Cancel</l-button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Select, Option, Button, Dialog, Table, TableColumn, Icon, Tooltip, Pagination } from 'element-ui';
import HostsAPIService from "src/servicehandlers/HostsAPIService";
import PluginsAPIService from "src/servicehandlers/PluginsAPIService";

const pluginsAPIService = new PluginsAPIService();
const hostsAPIService = new HostsAPIService();

export default {
  name: "Plugins",
  components: {
    [Icon.name]: Icon,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Tooltip.name]: Tooltip,
    [Pagination.name]: Pagination,
  },
  data() {
    return {
      searchQuery: '',
      selectedPlugins: [],
      spinning: false,
      selected_plugin_id: null,
      file_path: null,
      pluginFile: null,
      arch: null,
      currentPage: 1,
      pageSizes: [20, 50, 100, 200, 500, 1000],
      pageSize: 20,
      fields: [
        'name',
        'description',
        'arch',
        'md5sum',
        'plugin type',
        'host type',
        { key: 'actions', label: '' }
      ],
      md5sum: null,
      download_location: null,
      host_type_id: null,
      plugin_type_id: null,
      message: '',
      filename: null,
      name: null,
      plugin_type_name: null,
      newPlugin: {
        name: "",
        description: "",
        arch: "64-bit",
        plugin_type_id: null,
        host_type_id: null,
      },
      description: null,
      plugin: null,
      media: null,
      last_order: null,
      plugins: [],
      host_types: [],
      plugin_types: [],
      error_txt: null,
      success_txt: null,
      modals: {
        error: false,
        success: false,
        create: false,
        plugin: false,
        plugin_type: false,
      },
    }
  },
  watch: {
    filteredPlugins: {
      handler(newVal) {
        this.totalItems = newVal.length;
      },
      immediate: true  // to run the handler immediately after the watcher is created
    }
  },
  mounted() {
    this.getPlugins();
    this.getHostTypes();
    this.getPluginTypes();
  },
  computed: {
    filteredPlugins() {
      const query = this.searchQuery.toLowerCase();
      return this.plugins.filter(plugin => {
        const name = plugin.name ? plugin.name.toLowerCase() : '';
        const description = plugin.description ? plugin.description.toLowerCase() : '';

        return name.includes(query) ||
          description.includes(query)
      });
    },
    paginatedPlugins() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.filteredPlugins.slice(start, end);
    },
  },

  methods: {
    fileSelected(index) {
      if (!this.$refs[`fileInput${index}`].files.length) {
        alert('No file selected!');
        return;
      }
    },
    createNewPluginType() {
      this.closeModal('plugin_type');
      const newPlugintype = {
        name: this.plugin_type_name
      };
      return pluginsAPIService.createPluginType(newPlugintype)
      .then((response) => {
        this.plugin_types.push(response);
        const self = this;
        this.success_txt = 'Plugin type creation success';
        this.spinning = false;
        this.pluginFile = null;
        this.plugin_types = [];
        this.getPluginTypes();
        this.closeModal('plugin_type');
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to create new plugin type' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
    },
    async getPluginTypes() {
      return pluginsAPIService.getPluginTypes(this.$router)
      .then((response) => {
        this.plugin_types = response;
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get host types' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    async getHostTypes() {
      return hostsAPIService.getHostTypes(this.$router)
      .then((host_types) => {
        this.host_types = host_types;
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get host types' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    uploadFile() {
      this.spinning = true;
      this.files = this.$refs.pluginInput.files[0];
      let formData = new FormData();
      for (const fle of this.$refs.pluginInput.files) {
        formData.append('pluginInput', fle);
        formData.append('type', 'kicks');
        formData.append('id', this.selected_kick_id);
      }
      return fileAPI.uploadFile(formData, this.$router)
      .then((response) => {
        const self = this;
        this.success_txt = 'Plugin upload success';
        this.spinning = false;
        this.pluginFile = null;
        this.closeModal('plugin');
        this.openModal('success');
        this.getPlugins();
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'File upload error: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    uploadPlugin(event) {
      const files = event.target.files;
      if (files.length > 0) {
        this.pluginFile = files[0];
        this.message = '';
      }
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    deletePlugin(id) {
      return pluginsAPIService.deletePlugin(id, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Delete Plugin successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getPlugins();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error deleting Plugins: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    async getPlugins() {
      this.plugins = [];
      return await pluginsAPIService.getPlugins(this.$router)
      .then((response) => {
        this.plugins = response;
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error getting Plugins: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    savePlugin(plugin) {
      return pluginsAPIService.updatePlugin(plugin.id, plugin, this.$router)
      .then((resp) => {
        const self = this;
        this.getPlugins();
        this.success_txt = 'Update Plugin successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error getting Plugins: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    async setPlugin(row, index) {
      const formData = new FormData();

      const fileInput = this.$refs[`fileInput${index}`];
      if (!fileInput.files.length) {
        alert('Please select a file first.');
        return;
      }

      formData.append('file', fileInput.files[0]);
      formData.append("name", row.name);
      formData.append("description", row.description);
      formData.append("arch", row.arch);
      formData.append("host_type_id", row.host_type_id);
      formData.append("plugin_type_id", row.plugin_type_id);

      let plugin = null;
      try {
        plugin = await pluginsAPIService.updateOldPlugin(row.id, formData, this.$router);
        this.handleSuccess('Successfully updated plugin!');
        await this.getPlugins();
      } catch (e) {
        const self = this;
        this.error_txt = 'Error creating plugin: ' + e;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      }
    },
    async createPlugin() {
      this.closeModal('create');
      const formData = new FormData();
      formData.append('file', this.$refs.fileInput.files[0]);
      // Append additional data
      formData.append("name", this.newPlugin.name);
      formData.append("description", this.newPlugin.description);
      formData.append("arch", this.newPlugin.arch);
      formData.append("host_type_id", this.newPlugin.host_type_id);
      formData.append("plugin_type_id", this.newPlugin.plugin_type_id);

      let plugin = null;
      try {
        plugin = await pluginsAPIService.createPlugin(formData, this.$router);
        await this.getPlugins();
        this.handleSuccess('Successfully created plugin!');
      } catch (e) {
        const self = this;
        this.error_txt = 'Error creating plugin: ' + e;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      }
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleSizeChange(val) {
      // console.log(`Each page ${val} lines`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      //console.log(`Current page: ${val}`);
    },
  }
}
</script>

<style scoped>
.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
}
.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}
.icon-large {
  font-size: 20px;
}
.icon-red {
  color: red;
}
.icon-blue {
  color: blue;
}
.icon-green {
  color: green;
}
.fa-compact-disc {
  font-size: 18px;
  rotate: 90deg;
  color: cornflowerblue;
}
.card-title {
  font-size: 25px;
  color: dimgrey;
}
.fa-circle-plus {
  color: mediumseagreen;
  font-size: 25px;
}
.plugin-type-btn {
  background-color: mediumseagreen;
  color: white;
  font-size: 15px;
}
.center-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.custom-card {
  color: dimgrey;
  font-size: 25px;
}
.fa-floppy-disk {
  font-size: 19px;
  color: dodgerblue;
}
.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.choose-file {
  margin-top: 60px;
  margin-left: 60px;
}
.plugin-type {
  width: 300px;
}
</style>
